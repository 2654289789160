<template>
<div>
    <vx-card class="mt-8">
        <div class="card-title justify-between">
            <h2 class="w-auto">Review Details</h2>
            <vs-button title="Update" type="filled" class="small-btn bg-actionbutton" @click="getReviewList()">{{ LabelConstant.buttonLabelUpdate }}</vs-button>
        </div>
        <div class="review-location">
            <div class="vx-row">
                <div class="vx-col xl:w-1/4 select-location mb-6 lg-cus-6 md:w-1/2 w-full">
                    <span>Location</span>
                    <v-select v-model="locationId" :reduce="locations => locations.LocationId" :label="'LocationName'" :options="locationOptions" :clearable="false" />
                    <vs-button type="filled" color="primary" icon-pack="feather" icon="icon-refresh-cw" class="refresh-btn" @click="getLocationList()"></vs-button>
                </div>
                <div class="vx-col xl:w-1/4 mb-6 lg-cus-6 md:w-1/2 w-full">
                    <span>Date Range</span>
                    <!-- Date Picker -->
                    <date-range-picker class="mr-0 w-full p-0" :fromDateValue="fromDate" :toDateValue="toDate" @inputFromDate="
                newFromDate => {
                  fromDate = newFromDate;
                }
              " @inputToDate="
                newToDate => {
                  toDate = newToDate;
                }
              "></date-range-picker>
                </div>
                <div class="vx-col xl:w-1/4 mb-6 md:w-1/2 w-full">
                    <span>Published</span>
                    <v-select v-model="publishedId" :options="publishedOptions" :reduce="publish => publish.value" :clearable="false" label="text" />
                </div>
                <div class="vx-col xl:w-1/4 mb-6 md:w-1/2 w-full">
                    <span>Shared</span>
                    <v-select v-model="sharedId" :options="sharedOptions" :reduce="share => share.value" :clearable="false" label="text" />
                </div>
                <div class="vx-col xl:w-1/2 mb-6 lg-cus-6 md:w-1/2 w-full">
                    <span>Rating</span>
                    <v-select class="multiple-select" v-model="ratingId" :options="ratingOptions"  label="text" multiple />
                </div>

                <div class="vx-col xl:w-1/2 mb-6 lg-cus-6 md:w-1/2 w-full">
                    <span>Response</span>
                    <v-select v-model="responseId" :options="responseOptionsFilter" :reduce="response => response.value" :clearable="false" label="text" />
                </div>
                <div class="vx-col mb-6 w-full">
                    <span>Source</span>
                    <v-select class="multiple-select source-select" v-model="sourcesId" :options="sourcesOptions" :reduce="source => source.Id" label="Source_Name" multiple />
                </div>
            </div>
        </div>
        <div class="review-table">

            <div class="common-table">      
                <div class="table-row-select">
                    <vs-select label="Show Entries" v-model="perPage" class="show-select" autocomplete>
                        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in recordList" />
                    </vs-select>
                </div>
                <vs-table :data="reviewData" ref="tableData" :max-items="perPage" pagination search>
                    <template slot="thead">
                        <vs-th></vs-th>
                        <vs-th>Site</vs-th>
                        <vs-th sort-key="sortableDate">Date</vs-th>
                        <vs-th class="width-120px" sort-key="LocationName">Location</vs-th>
                        <vs-th sort-key="ReviewerName">Reviewer</vs-th>
                        <vs-th sort-key="Rating">Rating</vs-th>
                        <vs-th sort-key="ReviewText">Review</vs-th>
                        <vs-th sort-key="status">Status</vs-th>
                        <vs-th sort-key="shared">Shared</vs-th>
                        <vs-th sort-key="published">Published</vs-th>
                    </template>
                    <template slot-scope="{ data }">
                        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                            <vs-td>
                                <vs-button @click="populatePopupData(tr)" color="primary" :title="LabelConstant.buttonTitleEdit" type="filled" icon-pack="feather" icon="icon-edit" class="mr-2 float-left"></vs-button>
                            </vs-td>
                            <vs-td width="50">
                                <img :src="tr.SourceIconUrl" />
                            </vs-td>
                            <vs-td :data="tr.sortableDate">
                                {{ tr.DateReviewed }}
                            </vs-td>
                            <vs-td class="whitespace-remove" :data="tr.LocationName">
                                {{ tr.LocationName }}
                            </vs-td>
                            <vs-td :data="tr.ReviewerName">
                                {{ tr.ReviewerName }}
                            </vs-td>
                            <vs-td :data="tr.Rating">
                                <star-rating :rating="tr.Rating" :show-rating="false" :read-only="true"></star-rating>
                            </vs-td>
                            <vs-td class="whitespace-remove" :data="tr.ReviewText">
                                <p class="review-td-title" v-if="tr.ReviewTitle && tr.ReviewTitle.trim().length"><strong>{{tr.ReviewTitle}}</strong></p>
                                <p class="review-info-td">{{ tr.ReviewText }}</p>
                            </vs-td>
                            <vs-td :data="tr.ReviewStatus" width="150">
                                <vs-chip color="success" v-if="tr.ReviewStatus == 'Responded To'">Responded</vs-chip>
                                <vs-chip color="danger" v-if="tr.ReviewStatus == 'Not Responded To'">Not Responded</vs-chip>
                                <vs-chip color="danger" v-if="tr.ReviewStatus == 'No Action Needed'">No Action Needed</vs-chip>
                            </vs-td>
                            <vs-td :data="tr.shared" width="120">
                                <vs-chip color="success" v-if="tr.ReviewShared">Shared</vs-chip>
                                <vs-chip color="danger" v-else>Not Shared</vs-chip>
                            </vs-td>
                            <vs-td :data="tr.ReviewPublished" width="140">
                                <vs-chip color="success" v-if="tr.ReviewPublished">Published</vs-chip>
                                <vs-chip color="danger" v-else>Not Published</vs-chip>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
                <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">
                    Showing {{ showingDataFrom }} to {{ showingDataTo }} of
                    {{ reviewData.length|tableRecordNumberFormatter }} entries
                </p>
                <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">
                    Showing {{ showingDataFrom }} to {{ showingSearchedDataTo }} of
                    {{ totalSearchedData|tableRecordNumberFormatter }} entries (filtered from
                    {{ reviewData.length|tableRecordNumberFormatter }} entries)
                </p>
                <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">
                    Showing 0 to 0 of 0 entries
                </p>
            </div>
             
        </div>
    </vx-card>
    <!-- Edit Review -->
    <vs-popup class="tag-info" title="Edit Review" :active.sync="reviewDetailspopupActive" v-if="reviewPopupDetail">
        <div class="edit-review">
            <div class="edit-title">
                <img :src="reviewPopupDetail.SourceIconUrl" />
                <div>
                    <h5>
                        <b>{{ reviewPopupDetail.ReviewerName }}</b>Reviewed your business on {{ reviewPopupDetail.SourceName }}
                    </h5>
                    <span>{{ reviewPopupDetail.DateReviewed }}</span>
                </div>
            </div>
            <star-rating :rating="reviewPopupDetail.Rating" :show-rating="false" :read-only="true"></star-rating>
            <p>{{ reviewPopupDetail.ReviewText }}</p>
            <ul>
                <li>
                    <vs-chip color="primary" v-if="reviewPopupDetail.ReviewStatus == 'Responded To'" class="mr-2">Responded To</vs-chip>
                </li>
                <li>
                    <vs-chip color="primary" v-if="reviewPopupDetail.ReviewPublished">Published</vs-chip>
                </li>
            </ul>
        </div>
        <vx-card class="mt-4" v-if="reviewPopupDetail.ReviewResponses.length > 0" 
            v-for="(reviewResponse, index) in reviewPopupDetail.ReviewResponses" :key="index">
            <div class="top-title">
                <img :src="reviewPopupDetail.SourceIconUrl" />
                <div class="respond-right">
                    <h4><b>Responded</b></h4>
                    <span>{{ reviewResponse.ResponseDate }}</span>
                </div>
                <p>{{ reviewResponse.ResponseMsg }}</p>
            </div>
        </vx-card>
        <div class="publish-review">
            <div class="vx-row flex">
                <div class="vx-col sm:w-1/3 flex items-center w-full" v-if="!postRespond">
                    <vs-checkbox class="ml-0" v-model="reviewPopupDetail.ReviewPublished">Published</vs-checkbox>
                </div>
                <div class="vx-col sm:w-2/3 w-full mt-3 sm:mt-0 justify-end flex items-center" v-if="!postRespond">
                    <span class="float-left pr-3 justify-end flex">Change Status</span>
                    <v-select v-model="reviewPopupDetail.ReviewStatus" :options="responseOptions" :reduce="response => response.value" :clearable='false' label="text" class="mt-0 float-left w-2/3" />
                </div>
                <div class="vx-col w-full flex-wrap mt-3 justify-end flex" v-if="!postRespond">
                    <social-sharing :url="reviewPopupDetail.ReviewURL" :title="reviewPopupDetail.ReviewTitle" inline-template>
                        <div>
                            <network network="facebook">
                                <vs-button type="filled" class="mr-2 mb-2 p-2 bg-actionbutton">{{ LabelConstant.buttonLabelShareOnFacebook }}</vs-button>
                            </network>
                        </div>
                    </social-sharing>
                    <a :href="reviewPopupDetail.ReviewURL" target="_blank">
                        <vs-button type="filled" class="mr-2 mb-2 p-2 bg-actionbutton">{{ LabelConstant.buttonLabelGoToReview }}</vs-button>
                    </a>
                    <vs-button v-if="reviewPopupDetail.SourceIcon == 'google maps'" type="filled" class="p-2 mb-2 bg-actionbutton" @click="(postRespond = true), (updateReview = false)">{{ LabelConstant.buttonLabelRespond }}</vs-button>
                    <a v-else :href="reviewPopupDetail.ReviewURL" target="_blank">
                        <vs-button type="filled" class="mr-2 mb-2 p-2 bg-actionbutton">{{ LabelConstant.buttonLabelRespond }}</vs-button>
                    </a>
                </div>
            </div>
            <div class="vx-col w-full flex-wrap mt-3 justify-end flex" v-if="postRespond">
                <div class="form-group w-full float-left">
                    <form data-vv-scope="postResponseForm">
                        <label for="">Response</label>
                        <vs-textarea v-model="editRespondMessage" name="editRespondMessage" v-validate="'required'" />
                        <span class="text-danger text-sm">{{
                errors.first("postResponseForm.editRespondMessage")
              }}</span>
                    </form>
                </div>
                <div class=" form-action w-full float-left text-right">
                    <vs-button type="filled" class="p-2 bg-actionbutton" @click="respondToReview()">{{ LabelConstant.buttonLabelPostyourResponse }}</vs-button>
                    <vs-button color="grey" type="border" class="ml-2 p-2 grey-btn" @click="(postRespond = false), (updateReview = true)">{{ LabelConstant.buttonLabelCancel }}</vs-button>
                </div>
            </div>
        </div>
        <div class="float-right mt-6 mb-8">
            <vs-button type="filled" :disabled="!updateReview" @click="updateReviewData()" color="primary">{{ LabelConstant.buttonLabelUpdateReview }}</vs-button>
            <vs-button @click="reviewDetailspopupActive = false" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>
</div>
</template>

<script>
import Vue from "vue";
import VxCard from "@/components/vx-card/VxCard";
import DateRangePicker from "../../components/filter/DateRangePicker";
import vSelect from "vue-select";
import StarRating from "vue-star-rating";

import {
    Validator
} from "vee-validate";

var SocialSharing = require("vue-social-sharing");
Vue.use(SocialSharing);

const dict = {
    custom: {
        editRespondMessage: {
            required: "Please enter a response to post."
        }
    }
};

Validator.localize("en", dict);

export default {
    components: {
        VxCard,
        vSelect,
        DateRangePicker,
        StarRating
    },
    data() {
        return {
            //From Date
            fromDate: null,
            //To Date
            toDate: null,

            // Locations
            locationId: "all",
            locationOptions: [],

            //Store selected publish id
            publishedId: "all",
            publishedOptions: [{
                    text: "All",
                    value: "all"
                },
                {
                    text: "Published",
                    value: "true"
                },
                {
                    text: "Not Published",
                    value: "false"
                }
            ],

            //Store selected shared id
            sharedId: "all",
            sharedOptions: [{
                    text: "All",
                    value: "all"
                },
                {
                    text: "Shared",
                    value: "true"
                },
                {
                    text: "Not Shared",
                    value: "false"
                }
            ],

            //Store selected rating ids
            ratingId: [1, 2, 3, 4, 5],
            ratingOptions: [{
                    text: "1 Star",
                    value: 1
                },
                {
                    text: "2 Star",
                    value: 2
                },
                {
                    text: "3 Star",
                    value: 3
                },
                {
                    text: "4 Star",
                    value: 4
                },
                {
                    text: "5 Star",
                    value: 5
                }
            ],

            //Store selected source ids
            sourcesId: [],
            sourcesOptions: [],

            //Store selected response ids
            responseId: "all",
            responseOptionsFilter: [{
                    text: "All",
                    value: "all"
                },
                {
                    text: "Responded To",
                    value: "true"
                },
                {
                    text: "Not Responded To",
                    value: "false"
                }
            ],

            //Review Datatable
            reviewData: [],
            perPage: 5,
            recordList: [{
                    text: "5",
                    value: 5
                },
                {
                    text: "10",
                    value: 10
                },
                {
                    text: "25",
                    value: 25
                },
                {
                    text: "50",
                    value: 50
                },
                {
                    text: "100",
                    value: 100
                }
            ],

            //Review details popup
            reviewDetailspopupActive: false,
            reviewPopupDetail: null,

            responseOptions: [{
                    text: "Responded To",
                    value: "Responded To"
                },
                {
                    text: "Not Responded To",
                    value: "Not Responded To"
                },
                {
                    text: "No Action Needed",
                    value: "No Action Needed"
                }
            ],

            postRespond: false,
            updateReview: true,
            editRespondMessage: null
        };
    },
    created() {
        this.setFromDate();
        this.setToDate();

        this.getLocationList();
    },
    methods: {
        //Get list of the locations
        getLocationList() {
            this.$vs.loading();
            this.axios
                .get("/ws/ReviewsGetStarted/GetLocationList")
                .then(response => {
                    let data = response.data;
                    if (data.length <= 0) {
                        this.$router.push(
                            "/reputation-management/getting-started/uid/" +
                            this.$route.params.id +
                            "?setLocation=true"
                        );
                    }
                    this.locationOptions = [];
                    this.locationOptions.push({
                        LocationId: "all",
                        LocationName: "All Locations"
                    });
                    for (let i = 0; i < data.length; i++) {
                        this.locationOptions.push(data[i]);
                    }
                    this.locationId = "all";
                    setTimeout(() => {
                        this.getAvailableSources();
                        this.getReviewList();
                    }, 200);
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Get list of sources
        getAvailableSources() {
            let sourcesParams = {
                CompanyId: this.locationId,
                Date_End: this.toDate,
                Date_Start: this.fromDate,
                HasResponse: this.responseId,
                Ratings: [1, 2, 3, 4, 5],
                ReviewPublished: this.publishedId,
                ReviewShared: this.sharedId,
                Sources: []
            };
            this.$vs.loading();
            this.axios
                .post("/ws/ReviewSummary/GetSelectedSources", sourcesParams)
                .then(response => {
                    let data = response.data;
                    this.sourcesOptions = data;
                    for (var i = 0; i < this.sourcesOptions.length; i++) {
                        this.sourcesId.push(this.sourcesOptions[i].Id);
                    }
                    setTimeout(() => {
                        this.getReviewList();
                    }, 200);
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Get list of reviews
        getReviewList() {
            let reviewListParams = {
                CompanyId: this.locationId,
                Date_End: this.toDate,
                Date_Start: this.fromDate,
                HasResponse: this.responseId,
                Ratings: this.ratingId,
                ReviewPublished: this.publishedId,
                ReviewShared: this.sharedId,
                Sources: this.sourcesId,
                ReviewStatus: [
                    "no action required",
                    "responded",
                    "action required",
                    "no status"
                ]
            };
            this.$vs.loading();
            this.axios
                .post("/ws/ReviewSummary/GetReviews", reviewListParams)
                .then(response => {
                    let data = response.data;
                    this.reviewData = data;
                    this.$refs.tableData.currentx = 1
                    this.$refs.tableData.searchx = ""
                    this.reviewData.map(function (manipulatedData) {
                        var tempDate = manipulatedData.DateReviewed.split("T")[0];
                        var reviewDate =
                            tempDate.split("-")[1] +
                            "/" +
                            tempDate.split("-")[2] +
                            "/" +
                            tempDate.split("-")[0];
                        manipulatedData.DateReviewed = reviewDate;
                        manipulatedData.sortableDate = new Date(
                            manipulatedData.DateReviewed
                        );
                        return manipulatedData;
                    });

                    // this.reviews.sort(this.GetSortOrder("sortByDate", 'ASC')); //Pass the attribute to be sorted on
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Populate review details popup data
        populatePopupData(reviewPopupData) {
            this.reviewPopupDetail = reviewPopupData;
            this.reviewDetailspopupActive = true;
            // console.log(reviewPopupData)
        },
        //Respond to review
        respondToReview() {
            this.$validator.validateAll("postResponseForm").then(result => {
                if (result) {
                    let respondParams = {
                        LocationId: this.reviewPopupDetail.LocationId,
                        ReviewId: this.reviewPopupDetail.Id,
                        ReviewResponse: this.editRespondMessage,
                        ReviewResponseType: this.reviewPopupDetail.SourceName
                    };
                    this.$vs.loading();
                    this.axios
                        .post("/ws/ReviewSummary/RespondToReview", respondParams)
                        .then(() => {
                            this.postRespond = false;
                            this.updateReview = true;
                            this.editRespondMessage = null;
                            this.reviewPopupDetail.ReviewStatus = "Responded To";
                            this.reviewPopupDetail.ReviewStatus = "Responded To";
                            this.$vs.notify({
                                title: "Success",
                                text: "Your response has been posted successfully",
                                color: "success",
                                iconPack: "feather",
                                icon: "icon-check",
                                position: "top-right",
                                time: 4000
                            });
                            this.$vs.loading.close();
                        })
                        .catch(e => {
                            this.$vs.loading.close();
                            this.showError(e);
                            this.$vs.notify({
                                title: "Error",
                                text: e.response.data.Errors[0].Message,
                                color: "danger",
                                iconPack: "feather",
                                icon: "icon-alert-circle",
                                position: "top-right",
                                time: 4000
                            });
                        });
                }
            });
        },
        //Update review details
        updateReviewData() {
            let updateReviewParams = {
                LocationId: this.reviewPopupDetail.LocationId,
                ReviewId: this.reviewPopupDetail.Id,
                ReviewStatus: this.reviewPopupDetail.ReviewStatus,
                ReviewPublished: this.reviewPopupDetail.ReviewPublished,
                ReviewShared: false
            };
            this.$vs.loading();
            this.axios
                .post("/ws/ReviewSummary/UpdateReview", updateReviewParams)
                .then(() => {
                    this.getReviewList();
                    this.postRespond = false;
                    this.updateReview = true;
                    this.reviewDetailspopupActive = false;
                    this.$vs.notify({
                        title: "Success",
                        text: "Your review has been posted successfully",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        }
    },
    computed: {
        /**
         * Showing Entries details
         * isTableRecordEntriesDisplay()
         * isSearch()
         * showingDataFrom()
         * showingDataTo()
         * totalSearchedData()
         * showingSearchedDataTo()
		 *  */
        isTableRecordEntriesDisplay() {
            if (this.reviewData.length > 0) {
                if (this.$refs.tableData.datax.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        isSearch: function () {
            if (this.reviewData.length > 0) {
                if (
                    this.$refs.tableData.searchx == null ||
                    this.$refs.tableData.searchx == ""
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        showingDataFrom: function () {
            if (this.reviewData.length > 0) {
                return (this.$refs.tableData.currentx - 1) * this.perPage + 1;
            }
        },
        showingDataTo: function () {
            if (this.reviewData.length > 0) {
                let totalPages = this.$refs.tableData.getTotalPages;
                if (totalPages == this.$refs.tableData.currentx) {
                    return (
                        (this.$refs.tableData.currentx - 1) * this.perPage +
                        this.$refs.tableData.datax.length
                    );
                } else {
                    return this.$refs.tableData.currentx * this.perPage;
                }
            }
        },
        totalSearchedData: function () {
            if (this.reviewData.length > 0) {
                return this.$refs.tableData.queriedResults.length;
            }
        },
        showingSearchedDataTo: function () {
            if (this.reviewData.length > 0) {
                let totalPages = this.$refs.tableData.getTotalPagesSearch;
                if (totalPages == this.$refs.tableData.currentx) {
                    return (
                        (this.$refs.tableData.currentx - 1) * this.perPage +
                        this.$refs.tableData.datax.length
                    );
                } else {
                    return this.$refs.tableData.currentx * this.perPage;
                }
            }
        }
    }
};
</script>
